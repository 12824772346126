import { Card } from "@components/shadcn/ui/card";
import moment from "moment";
import React from "react";
import TexteScroller from "./TexteScroller";
function NextTransportsCard({ driverTransport }) {
  const { carType, startDate, endDate, patient, notifType, notifCreatedAt } =
    driverTransport;
  const { firstName, lastName } = driverTransport.patient;
  const { postalCode: postalCodeFrom, city: cityFrom } = driverTransport.from;
  const { postalCode: postalCodeTo, city: cityTo } = driverTransport.to;
  const { driverStatus } = driverTransport;
  return (
    <Card className="bg-electricIndigo10 border-0 relative mb-5 p-3">
      <div className="w-full  flex flex-col">
        <div className="w-full h-auto  flex flex-row items-start">
          <div className="w-1/12">
            {carType === "VSL" && (
              <img
                className="mt-1"
                width={25}
                src="/icons/veicule_info_modal/vslcar.svg"
              />
            )}
            {carType === "AMBULANCE" && (
              <img width={25} src="/icons/veicule_info_modal/ambulance.svg" />
            )}
            {carType === "AMBULANCE" ? (
              <p
                style={{
                  height: "fit-content",
                  fontSize: 11,
                  fontStyle: "italic",
                  color: "#4951ff",
                  fontWeight: "bold",
                  paddingTop: 2
                }}
              >
                AMB
              </p>
            ) : (
              <p
                style={{
                  height: "fit-content",
                  fontSize: 11,
                  fontStyle: "italic",
                  color: "#FFB960",
                  fontWeight: "bold",
                  textAlign: "center",
                  paddingTop: 2
                }}
              >
                {carType}
              </p>
            )}
          </div>
          <div className="w-7/12 pl-2 flex flex-col items-start">
            <div className="flex">
              <p
                style={{
                  height: "fit-content",
                  fontSize: 10,
                  lineHeight: 1.9,
                  fontWeight: "bold",
                  color: "#DB2C73",
                  marginRight: 3,
                  fontStyle: "italic"
                }}
              >
                {patient.gender === "male" ? "Mr" : "Mme"}
              </p>

              <TexteScroller
                style={{
                  width: 175,
                  height: "fit-content",
                  fontSize: 16,
                  lineHeight: 1,
                  fontWeight: "bold",
                  color: "#02196C"
                }}
              >{`${firstName}.${lastName}`}</TexteScroller>
            </div>
            <div>
              <div className="flex flex-row items-baseline">
                {driverTransport?.returnTransportId && (
                  <img width={15} src="/icons/veicule_info_modal/ar.svg" />
                )}

                <p
                  style={{
                    marginRight: 4,
                    fontSize: 12,
                    color: "#02196C60",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    height: "fit-content",
                    lineHeight: 1
                  }}
                >
                  Transport <span style={{ color: "#DB2C73" }}> Aller</span>
                </p>
              </div>
            </div>
          </div>
          <div className="w-4/12  h-100">
            <div className="flex justify-end flex-col whitespace-nowrap text-end">
              <p
                style={{
                  color: "#7000FF",
                  fontSize: 12,
                  fontWeight: "bold",
                  textAlign: "end"
                }}
              >
                {driverStatus === "PENDING" && "Attribué"}
                {driverStatus === "ACCEPTED" && "Accepté"}
              </p>
              <p
                style={{ fontSize: "11px", lineHeight: 1 }}
                className="font-thin text-electricIndigo text-end"
              >
                {/* {notifType === "TRANSPORT_ATTRIBUTED_ACCEPTED"
                  ? "Accepté à "
                  : "En Attente "}
                {`${moment(notifCreatedAt).format("LT")}`} */}
              </p>
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <div className="w-8">
              <p
                style={{ fontSize: 11, lineHeight: 1.5 }}
                className="text-skyBlue2 font-bold text-sm"
              >
                {moment(startDate).format("LT")}
              </p>
            </div>
            <div className="w-8">
              <p
                style={{ fontSize: 11, lineHeight: 1.5 }}
                className="text-pink font-bold text-sm"
              >
                {moment(endDate).format("LT")}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingInline: 4
            }}
          >
            <img width={12} src="/icons/transport_regulation/trip.svg" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center"
            }}
          >
            <div className="flex">
              <TexteScroller className="w-[230px] text-skyBlue90 text-[11px] leading-[1.5]">
                {`${postalCodeFrom} - ${cityFrom}`}
              </TexteScroller>
            </div>
            <div className="flex">
              <p
                style={{ fontSize: 11, lineHeight: 1.5 }}
                className="text-pink90"
              >
                {`${postalCodeTo} - ${cityTo}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default NextTransportsCard;
