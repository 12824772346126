import { useZustandStore } from "@helpers/store";
import moment from "moment";
import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import TransportBourseCard from "./TransportBourseCard";

function TransportBourseList({ transports }) {
  const setBourseTransport = useZustandStore(
    (state) => state.setBourseTransport
  );
  const setGlobalRef = useZustandStore((state) => state.setGlobalRef);

  const setIsBourseModalOpen = useZustandStore(
    (state) => state.setIsBourseModalOpen
  );
  const setIsDriverModalOpen = useZustandStore(
    (state) => state.setIsDriverModalOpen
  );
  const setIsRegulationModalOpen = useZustandStore(
    (state) => state.setIsRegulationModalOpen
  );
  const setIsOpen = useZustandStore((state) => state.setIsOpen);
  const setTransportId = useZustandStore((state) => state.setTransportId);
  const [isSelected, setIsSelected] = useState(false);
  return (
    <div style={{ height: "574px" }} className={`custom-scrollbar`}>
      {transports.length > 0 &&
        transports.map((item, i) => {
          const prevStartDate = i > 0 ? transports[i - 1].startDate : null;
          const isSameAsPrevDay =
            prevStartDate && moment(prevStartDate).isSame(item.startDate, "d");
          return (
            <div
              onClick={(e) => {
                setBourseTransport(item);
                setGlobalRef(e.target);
                setIsBourseModalOpen(true);
                setIsRegulationModalOpen(false);
                setIsOpen(false);
                setIsDriverModalOpen([false, false]);
                setTransportId(item.id);
                setIsSelected(true);
              }}
              style={{ width: "320px" }}
              key={item.id}
            >
              {!isSameAsPrevDay && (
                <VisibilitySensor offset={{ top: 300 }} partialVisibility>
                  <div
                    className="capitalize"
                    id={moment(item.startDate).format("YYYY-MM-DD")}
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      marginBottom: 5,
                      marginTop: i !== 0 ? 5 : 0,
                      color: "#02196C"
                    }}
                  >
                    {moment(item.startDate).format("dddd DD MMMM YYYY")}
                  </div>
                </VisibilitySensor>
              )}

              <TransportBourseCard
                isSelected={isSelected}
                key={item.id}
                bourseTransport={item}
              />
            </div>
          );
        })}
    </div>
  );
}

export default TransportBourseList;
