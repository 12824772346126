import Button from "@components/Button";
import { DatePicker } from "@components/Picker/DatePicker";
import Palette from "@styles/Palette";
import moment from "moment";
import React, { useEffect, useState } from "react";
function TransportRegulationFilter({
  searchFilter,
  setSearchFilter,
  handleFilter,
  filterState,
  handleDateFilter,
  OpenDropDown = () => {}
}) {
  const [transportDate, setTransportDate] = useState(moment());

  useEffect(() => {
    handleDateFilter(transportDate);
  }, [transportDate]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}
      className="my-3"
    >
      <div style={{ display: "flex" }} className="w-28 justify-between">
        <Button
          onClick={() => {
            setSearchFilter({ carType: "VSL" });
            handleFilter(0);
          }}
          imgTop="icons/veicule_info_modal/vslcar.svg"
          text="VSL"
          variant={filterState[0] ? "activeFilter" : "transportTrackingBtns"}
          iconStyle={{ width: "25px" }}
          style={{ color: Palette.kingNacho }}
          containerStyle={{
            display: "flex",
            flexDirection: "column",
            width: 50,
            height: 50,
            cursor: "pointer",
            justifyContent: "space-around",
            paddingTop: 4
          }}
        />

        <Button
          onClick={(e) => {
            setSearchFilter({ carType: "AMBULANCE" });
            handleFilter(1);
          }}
          imgTop="icons/veicule_info_modal/ambulance.svg"
          text="AMB"
          variant={filterState[1] ? "activeFilter" : "transportTrackingBtns"}
          iconStyle={{ width: "25px" }}
          style={{ color: Palette.skyBlue2 }}
          containerStyle={{
            display: "flex",
            flexDirection: "column",
            width: 50,
            height: 50,
            cursor: "pointer",
            justifyContent: "space-around",
            paddingTop: 4
          }}
        />
      </div>
      <div
        style={{ display: "flex", flexDirection: "column" }}
        className="w-7/12 justify-between"
      >
        <div className="w-[143px]">
          <p className="text-lightBlue leading-3 font-semibold whitespace-nowrap text-center text-sm">
            Date du transport
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <DatePicker
            date={transportDate}
            setDate={setTransportDate}
            placeholder="Select a date"
            className="my-datepicker"
          />
          <div
            onClick={() => OpenDropDown(true)}
            className="w-20 cursor-pointer"
            style={{ display: "grid" }}
          >
            <img
              width={32}
              height={32}
              src="/icons/transport_regulation/filter.svg"
              alt="filter"
              style={{ justifySelf: "end" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransportRegulationFilter;
