import Input from "@components/Input";
import Palette from "@styles/Palette";

import { useZustandStore } from "@helpers/store";
import React, { useEffect, useState } from "react";
import TransportTrackingFilter from "./TransportTrackingFilter";
import TransportTrackingList from "./TransportTrackingList";

function TransportTracking({
  Global,
  setGlobal,
  RegulatorTransportCard,
  transportLists,
  setTransportSelected,
  transportSelected,
  type,
  setTransportToAssign,
  setSubNavBarIsOpen,
  drivers
}) {
  const [searchValue, setSearchValue] = useState("");
  const [searchFilter, setSearchFilter] = useState({
    carType: "",
    vehiculeStatus: ""
  });
  const [driversWithFilter, setDriversWithFilter] = useState(drivers);
  const [filtredTransports, setFiltredTransports] = useState(transportLists);
  const [filterState, setFilterState] = useState([false, false, false, false]);
  const setTrackedVehiculeTransport = useZustandStore(
    (state) => state.setTrackedVehiculeTransport
  );
  const setAvailableDriver = useZustandStore(
    (state) => state.setAvailableDriver
  );
  const setGlobalRef = useZustandStore((state) => state.setGlobalRef);
  const handleFilter = (index) => {
    setFilterState((prevStates) => {
      const newState = [...prevStates];
      if (index === 0 || index === 1) {
        newState[index] = !newState[index];
        if (newState[0] && newState[1]) {
          newState[index === 0 ? 1 : 0] = false;
        } else if (newState[1]) {
          newState[0] = false;
        } else if (newState[0]) {
          newState[1] = false;
        }
      }
      if (index === 2 || index === 3) {
        newState[index] = !newState[index];
        if (newState[2] && newState[3]) {
          newState[index === 2 ? 3 : 2] = false;
        } else if (newState[3]) {
          newState[2] = false;
        } else if (newState[2]) {
          newState[3] = false;
        }
      }
      return newState;
    });
  };

  useEffect(() => {
    let allDrivers =
      transportLists && transportLists.length > 0
        ? transportLists.reduce(
            (driversCopy, t) => {
              if (
                t?.driverStatus === "RETRIEVING_PATIENT" ||
                t?.driverStatus === "DEPOSITING_PATIENT"
              ) {
                driversCopy = driversCopy.filter((d) => d.id !== t.driver.id);
              }
              return driversCopy;
            },
            [...drivers]
          )
        : drivers;
    const isSearchValue = searchValue === "";
    const filtredDrivers = allDrivers.filter((d) => {
      if (!isSearchValue) {
        return d.name.toLowerCase().includes(searchValue.toLowerCase());
      }
      if (
        filterState[0] &&
        !filterState[1] &&
        !filterState[2] &&
        !filterState[3] &&
        d.driverStatus === undefined &&
        searchFilter.carType !== ""
      ) {
        return d.carType === "VSL";
      }
      if (
        !filterState[0] &&
        filterState[1] &&
        !filterState[2] &&
        !filterState[3] &&
        d.driverStatus === undefined &&
        searchFilter.carType !== ""
      ) {
        return d.carType === "AMBULANCE";
      }
      if (
        filterState[0] &&
        !filterState[1] &&
        filterState[2] &&
        !filterState[3] &&
        d.driverStatus === undefined &&
        searchFilter.carType !== ""
      ) {
        return d.carType === "VSL";
      }
      if (
        !filterState[0] &&
        filterState[1] &&
        filterState[2] &&
        !filterState[3] &&
        d.driverStatus === undefined &&
        searchFilter.carType !== ""
      ) {
        return d.carType === "AMBULANCE";
      }
      if (
        !filterState[0] &&
        !filterState[1] &&
        filterState[2] &&
        !filterState[3]
      ) {
        return allDrivers;
      }
      if (
        !filterState[0] &&
        !filterState[1] &&
        !filterState[2] &&
        !filterState[3] &&
        searchValue === ""
      ) {
        return allDrivers;
      }
      return false;
    });
    setDriversWithFilter(filtredDrivers);

    const filtredTransports = transportLists.filter((t) => {
      if (
        !filterState[0] &&
        !filterState[1] &&
        !filterState[2] &&
        filterState[3]
      ) {
        return (
          t?.driverStatus === "RETRIEVING_PATIENT" ||
          t?.driverStatus === "DEPOSITING_PATIENT"
        );
      }
      if (
        filterState[0] &&
        !filterState[1] &&
        !filterState[2] &&
        !filterState[3]
      ) {
        return (
          t?.driverStatus === "RETRIEVING_PATIENT" ||
          t?.driverStatus === "DEPOSITING_PATIENT"
        );
      }
      if (
        !filterState[0] &&
        !filterState[1] &&
        filterState[2] &&
        !filterState[3]
      ) {
        return t.driverStatus === "ACCEPTED" || t.driverStatus === "PENDING";
      }
      if (
        filterState[0] &&
        !filterState[1] &&
        !filterState[2] &&
        filterState[3]
      ) {
        return (
          (t?.driverStatus === "RETRIEVING_PATIENT" ||
            t?.driverStatus === "DEPOSITING_PATIENT") &&
          t.driver.carType === "VSL"
        );
      }
      if (
        !filterState[0] &&
        !filterState[1] &&
        !filterState[2] &&
        !filterState[3] &&
        searchValue === ""
      ) {
        return transportLists;
      }
      // if (t?.driver?.name.toLowerCase().includes(searchValue.toLowerCase())) {
      //   return t?.driver?.carType === searchFilter.carType;
      // }
      if (searchValue !== "") {
        return t?.driver?.name
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      }
    });
    setFiltredTransports(filtredTransports);
  }, [searchValue, searchFilter, transportLists]);

  return (
    <div onClick={(e) => setGlobalRef(e.target)}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100px important",
          marginBottom: "20px"
        }}
      >
        <p
          style={{
            fontSize: 20,
            fontWeight: 700,
            color: Palette.nightBlue,
            lineHeight: 1
          }}
        >
          Suivi des véhicules
        </p>

        <div
          className="cursor-pointer"
          onClick={
            (() => {
              setTrackedVehiculeTransport(null);
              setAvailableDriver(null);
            },
            setSubNavBarIsOpen)
          }
        >
          <img
            width={20}
            height={20}
            src="icons/transport_tracking/close.svg"
            alt=""
          />
        </div>
      </div>
      <div className="my-3">
        <Input
          inputStyle={{
            backgroundColor: "white",
            color: Palette.nightBlue60
          }}
          inputContainerStyle={{
            backgroundColor: "white",
            borderRadius: 8
          }}
          placeholder="Rechercher un véhicule"
          imgLeft={"/icons/transport_tracking/icon-search.svg"}
          className="placeHolderStyle"
          style={{ border: "solid 2px", color: Palette.nightBlue60 }}
          onChange={setSearchValue}
          value={searchValue}
        />
      </div>
      <div>
        <TransportTrackingFilter
          handleFilter={handleFilter}
          searchFilter={setSearchFilter}
          filterState={filterState}
        />
      </div>
      <div
        className={"container flex"}
        style={{
          height: "345px",
          overflowY: "auto",
          scrollbarColor: "transparent transparent",
          scrollbarWidth: "none"
        }}
      >
        <TransportTrackingList
          containerStyle={{ flex: 1 }}
          Component={RegulatorTransportCard}
          isBourse={type === 4}
          transports={filtredTransports}
          selected={transportSelected}
          setSelected={setTransportSelected}
          onClick={(t) => {
            setTransportSelected(t);
            setTransportToAssign(null);
          }}
          onCancel={() => {
            setTransportSelected(null);
          }}
          onAccept={(t) => setGlobal({ transportToAssignAndChangePEC: t })}
          onAssign={(t) => setGlobal({ transportToAssignAndChangePEC: t })}
          onRefuse={(t) => setGlobal({ transportToRefuse: t })}
          onCancelOrBourse={(t) => {
            setGlobal({ transportToCancelOrBourse: t });
          }}
          drivers={driversWithFilter}
          searchValue={searchValue}
        />
      </div>
    </div>
  );
}

export default TransportTracking;
