import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle
} from "@components/shadcn/ui/card";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import TexteScroller from "./TexteScroller";
function PatientCard({ patient }) {
  const {
    birthDate = "",
    socialSecurityNumber = "",
    phoneNumber = "",
    firstName = "",
    lastName = "",
    id = "",
    gender = ""
  } = patient ?? {};

  const navigate = useHistory();
  const handleClick = () => {
    navigate.push(`?patient=${id}`);
  };

  // Current date
  const currentDate = moment();

  // Calculate age
  const age = currentDate.diff(birthDate, "years");
  const title = gender === "female" ? "Mme" : "Mr";
  return (
    <Card className="bg-lightGray2 border-4 border-strokGrayLight relative rounded-[1rem]">
      <CardHeader className="px-[0.5rem] pt-[0.3rem] pb-[0.5rem]">
        <div className="flex flex-row">
          <div className="w-10/12">
            <div className="flex flex-row mb-[5px]">
              <div className="w-1/6 flex flex-row justify-center items-center">
                <img
                  src="/icons/regulation_modal/patient.svg"
                  alt=""
                  className="w-[25px]"
                />
              </div>
              <div className="w-5/6">
                <CardTitle className="h-auto text-nightBlue font-bold text-lg">
                  <TexteScroller>
                    <span className="!text-pink">{title}</span> {firstName}{" "}
                    {lastName}
                  </TexteScroller>
                </CardTitle>
                <CardDescription className="h-auto text-lightBlue leading-3 pb-1 font-bold">
                  Né le
                  {` ${moment(birthDate).format("M/D/YYYY")} (${age}ans)`}
                </CardDescription>
              </div>
            </div>

            <CardDescription className="h-auto text-lightBlue leading-3 pb-1 flex flex-row">
              <div className="w-[39.06px]">
                <img
                  src="/icons/regulation_modal/patient-card.svg"
                  alt=""
                  className="w-[18px] m-auto"
                />
              </div>
              <div className="flex flex-col justify-center">
                <span className="!text-nightBlue">{` ${
                  socialSecurityNumber ?? ""
                }`}</span>
              </div>
            </CardDescription>
            <CardDescription className="h-auto text-lightBlue leading-3 flex flex-row">
              <div className="w-[39.06px]">
                <img
                  src="/icons/regulation_modal/patient-phone.svg"
                  alt=""
                  className="w-[18px] m-auto"
                />
              </div>
              <div className="flex flex-col justify-center">
                <span className="!text-nightBlue">{` ${
                  phoneNumber ?? ""
                }`}</span>
              </div>
            </CardDescription>
          </div>
          <div className="w-2/12 flex items-end justify-start">
            <button onClick={handleClick}>
              <ArrowRightIcon className="w-10 h-10  p-2 bottom-8 rounded-lg !bg-skyBlue10 text-skyBlue2" />
            </button>
          </div>
        </div>
      </CardHeader>
    </Card>
  );
}

export default PatientCard;
