import firebase from "firebase/compat/app";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { toast } from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom";
import processTransportForm from "../../../../actions/Transports";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import { useAuth } from "../../../../providers/Auth"; // eslint-disable-next-line no-unused-vars
import { formatTransportData } from "../../../../services/Firebase";
import Palette from "../../../../styles/Palette";
import Complements from "./Complements";
import PatientInformations from "./PatientInformations";
import Place from "./Place";
import Recapitulatif from "./Recapitulatif";
import TransportInformations from "./TransportInformations";
// eslint-disable-next-line no-unused-vars
const DEFAULT_TRANSPORT = {
  reason: "BACK_TO_HOME",
  carType: "VSL",
  patient: {
    gender: "male",
    firstName: "Logan",
    lastName: "LGR",
    birthDate: moment("19/11/1998", "DD/MM/YYYY").toDate(),
    address: {
      address: "101 rue prunier",
      postalCode: "33300",
      city: "Bordeaux"
    }
  },
  from: {
    addressType: "HOME",
    name: "Domicile",
    address: "101 rue prunier",
    postalCode: "33300",
    city: "Bordeaux"
  },
  to: {
    addressType: "HOME",
    name: "Domicile",
    address: "101 rue prunier",
    postalCode: "33300",
    city: "Bordeaux"
  }
};

export default () => {
  const { user } = useAuth();
  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const params = new URLSearchParams(location.search);
  const isVisible = !!params.get("editTransport");
  const transportId = params.get("editTransport");
  const initialStep = params.get("step");

  const [step, setStep] = useState(parseInt(initialStep) || 0);

  const [patient, setPatient] = useState();
  const [transportInformations, setTransportInformations] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [complement, setComplement] = useState();

  const stepRef = useRef();

  const steps = useMemo(
    () => [
      {
        Component: PatientInformations,
        props: { patient, setPatient },
        shortName: "Le patient",
        completedCondition:
          patient?.gender &&
          patient?.firstName &&
          patient?.lastName &&
          patient?.address?.address &&
          patient?.address?.city &&
          patient?.address?.postalCode
      },
      {
        Component: TransportInformations,
        props: {
          transport: transportInformations,
          setTransport: setTransportInformations,
          transportId
        },
        shortName: "Intervention",
        completedCondition:
          transportInformations?.carType && transportInformations?.reason
      },
      {
        Component: Place,
        props: {
          from,
          setFrom,
          to,
          setTo,
          patient
        },
        shortName: "Trajet",
        completedCondition:
          from?.name &&
          from.address &&
          from.postalCode &&
          from.city &&
          to?.name &&
          to.address &&
          to.postalCode &&
          to.city
      },
      {
        Component: Complements,
        props: {
          complement,
          setComplement
        },
        shortName: "Complément",
        completedCondition: !!complement
      },
      {
        Component: Recapitulatif,
        props: {
          transport: { patient, transportInformations, from, to, complement }
        },
        shortName: "Récapitulatif",
        completedCondition: !!complement
      }
    ],
    [patient, transportInformations, from, to, complement]
  );

  const { Component, props } = steps[step];

  const Steps = useCallback(
    () => (
      <div
        className={"containerRow"}
        style={{
          justifyContent: "space-evenly",
          alignItems: "center",
          height: 50,
          borderBottom: `1px solid ${Palette.grey}`,
          marginBottom: 20
        }}
      >
        {steps.map((s, i) => {
          const isLast = i === steps.length - 1;
          const isActualStep = step === i;

          return (
            <div
              key={i}
              onClick={() => {
                if (s.completedCondition) {
                  setStep(i);
                }
              }}
              className={"containerRow"}
              style={{
                opacity: s.completedCondition ? 1 : 0.6,
                marginRight: !isLast ? 20 : 0,
                cursor: "pointer"
              }}
            >
              <div
                style={{
                  width: 22,
                  height: 22,
                  backgroundColor: isActualStep
                    ? Palette.blue
                    : Palette.darkGrey,
                  borderRadius: 5,
                  color: Palette.white,
                  fontWeight: 800,
                  fontSize: 12,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 5
                }}
              >
                {i + 1}
              </div>
              <div style={{ color: Palette.darkGrey }}>{s.shortName}</div>
            </div>
          );
        })}
      </div>
    ),
    [step, steps]
  );

  useEffect(() => {
    if (transportId !== "new" && transportId !== null) {
      setLoading(true);
      firebase
        .firestore()
        .collection("transports")
        .doc(transportId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();
            // Assurez-vous que formatTransportData retourne une promesse
            formatTransportData(data)
              .then((formatedTransportData) => {
                setPatient(data.patient);
                setTransportInformations(formatedTransportData);
                setFrom(data.from);
                setTo(data.to);
                setComplement(data);
              })
              .catch((error) => {
                console.error(
                  "Erreur lors du formatage des données de transport:",
                  error
                );
              });
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération du document:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [transportId]);

  const onClose = () => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir quitter la création de ce transport ? Toutes les données seront perdues."
      )
    ) {
      setStep(0);
      setPatient(null);
      setTransportInformations(null);
      setComplement(null);
      setFrom(null);
      setTo(null);
      history.push(location.pathname);
    }
  };

  const onNextClick = async () => {
    try {
      if (step < steps.length - 1) {
        stepRef.current?.validateInputs();
        setStep((s) => s + 1);
      } else {
        try {
          await processTransportForm({
            transportInformations,
            patient,
            from,
            to,
            complement,
            regulatedBy: user.regulatedBy,
            role: user.role,
            transportId
          });
          toast.success("Transport(s) créé(s).");
          history.push(location.pathname);
          setPatient({});
          setTransportInformations({});
          setComplement({});
          setFrom({});
          setTo({});
          setStep(0);
        } catch (e) {
          toast.error(e.message);
          console.log(e);
        }
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const onPrevClick = () => {
    if (step > 0) {
      setStep((s) => s - 1);
    } else {
      onClose();
    }
  };

  return (
    isVisible && (
      <Modal
        showHeader={false}
        onClose={onClose}
        containerStyle={{ paddingLeft: 96, paddingRight: 96 }}
        style={{ width: 1000 }}
        onHeader={() => <Steps />}
        onFooter={() => (
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div
              onClick={onClose}
              style={{
                fontSize: 16,
                fontWeight: 500,
                color: Palette.blue,
                cursor: "pointer"
              }}
            >
              Annuler la demande
            </div>
            <div className={"containerRow"}>
              <Button
                text={"Retour"}
                variant={"secondary"}
                containerStyle={{ height: 40, marginRight: 10 }}
                onClick={onPrevClick}
              />
              <Button
                text={"Suivant"}
                containerStyle={{ width: 166, height: 40 }}
                onClick={onNextClick}
              />
            </div>
          </div>
        )}
      >
        {!loading && (
          <>
            <Component {...props} ref={stepRef} />
          </>
        )}
      </Modal>
    )
  );
};
