import React from "react";
import Checkbox from "../Checkbox";
import Dropdown from "../Dropdown";

export default ({
  containerStyle,
  selected,
  setSelected,
  data = [],
  isDropDownOpen,
  setIsDropDownOpen
}) => {
  const handleInputChange = (status) => {
    setSelected(status);
  };

  return (
    <Dropdown
      visible={isDropDownOpen}
      containerStyle={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        maxWidth: 186,
        top: 100,
        margin: "auto",
        left:131,
        right:0,
      }}
    >
      {data
        ?.filter((status) => status.value[0] !== "title")
        ?.map((status, i) => {
          const isLast = i === data?.length - 1;
          return (
            <div
              onClick={() => {
                setSelected(status);
                setIsDropDownOpen(false);
              }}
              className={"containerRow"}
              style={{
                cursor: "pointer",
                marginBottom: isLast ? 0 : 8,
                alignItems: "center"
              }}
              key={status?.value}
            >
              <Checkbox
                value={
                  Array.isArray(selected?.value)
                    ? selected?.value.includes(status?.value[0])
                    : selected?.value[0] === status?.value[0]
                }
                onChange={() => handleInputChange(status)}
                name={status?.name}
                style={{ marginRight: 8 }}
              />
            </div>
          );
        })}
    </Dropdown>
  );
};
