import { useZustandStore } from "@helpers/store";
import { useClickOutside } from "@hooks/useClickOutside";
import moment from "moment";
import React, { useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";
import TransportEnded from "./TransportEnded";
function DriverModal({ isOpen, driver, handleClick, transportsEnded, noInfo }) {
  driver;
  const { carType, name } = driver ?? "";
  const ref = useRef(null);
  const globalRef = useZustandStore((state) => state.globalRef);
  useClickOutside(ref, handleClick, globalRef);
  transportsEnded.sort((a, b) => b.startDate.valueOf() - a.startDate.valueOf());
  return (
    <div
      ref={ref}
      className="bg-background z-10  rounded-3xl border-solid border-4 border-borderV2 px-5 box-border relative custom-scrollbar"
      style={{
        display: `${isOpen ? "block" : "none"}`,
        width: "350px",
        height: "615px",
        top: 50
      }}
    >
      {}
      <div className="w-full pt-5 h-auto rounded-3xl flex flex-row  items-start mb-3">
        <div className="w-2/12 my-auto">
          {carType === "VSL" ? (
            <img
              width={35}
              src="/icons/veicule_info_modal/vslcar.svg"
              alt="VSL Car"
            />
          ) : carType === "AMBULANCE" ? (
            <img
              width={25}
              src="/icons/veicule_info_modal/ambulance.svg"
              alt="Ambulance"
            />
          ) : null}
        </div>

        <div className="w-6/12 flex flex-col">
          <div style={{ lineHeight: 1 }} className="flex w-full justify-start">
            <p
              style={{
                marginRight: 4,
                fontSize: 13,
                color:
                  carType === "AMBULANCE"
                    ? "#4951FF" // Blue for AMB
                    : carType === "VSL"
                    ? "#FFB960" // Yellow for VSL
                    : "#000", // Default color
                fontStyle: "italic",
                alignSelf: "flex-end"
              }}
            >
              {driver
                ? carType === "AMBULANCE"
                  ? "AMB"
                  : carType === "VSL"
                  ? "VSL"
                  : null
                : null}
            </p>

            <p
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: "#02196C"
              }}
            >
              {driver && name}
            </p>
          </div>
        </div>
        <div className="h-full">
          <div
            className="flex justify-center border w-24"
            style={{
              border: "solid 1px #DEE0F3",
              borderRadius: 10,
              backgroundColor: "#F6F7FF"
            }}
          >
            {driver?.driverStatus === "RETRIEVING_PATIENT" ||
            driver?.driverStatus === "DEPOSITING_PATIENT" ? (
              <>
                <img
                  width={10}
                  src="/icons/veicule_info_modal/dot_red.svg"
                  className="mr-1"
                />
                <p
                  style={{
                    color: "#FFBBC2",
                    marginLeft: 2,
                    fontSize: 12,
                    fontWeight: "bold"
                  }}
                >
                  En course
                </p>
              </>
            ) : (
              <>
                <img
                  width={10}
                  src="/icons/veicule_info_modal/dot_blue.svg"
                  className="mr-1"
                />
                <p
                  style={{
                    color: "#506BFF",
                    marginLeft: 2,
                    fontSize: 11,
                    fontWeight: "bold"
                  }}
                >
                  Disponible
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      {transportsEnded.length === 0 && <h3>Aucun transport</h3>}
      {transportsEnded && (
        <div style={{ height: "650px" }} className={`custom-scrollbar`}>
          {transportsEnded &&
            transportsEnded.map((item, i) => {
              const prevStartDate =
                i > 0 ? transportsEnded[i - 1].startDate : null;
              const isSameAsPrevDay =
                prevStartDate &&
                moment(prevStartDate).isSame(item.startDate, "d");
              return (
                <div style={{ width: "302px" }} key={item.id}>
                  {!isSameAsPrevDay && (
                    <VisibilitySensor offset={{ top: 300 }} partialVisibility>
                      <div
                        className="capitalize"
                        id={moment(item.startDate).format("YYYY-MM-DD")}
                        style={{
                          fontSize: 17,
                          fontWeight: "bold",
                          marginBottom: 10,
                          marginTop: i !== 0 ? 5 : 0,
                          color: "#02196C"
                        }}
                      >
                        {moment(item.startDate).format("dddd DD MMMM YYYY")}
                      </div>
                    </VisibilitySensor>
                  )}
                  <TransportEnded key={item.id} transportEnded={item} />
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default DriverModal;
