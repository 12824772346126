import { useZustandStore } from "@helpers/store";
import Palette from "@styles/Palette";
import moment from "moment";
import React, { useEffect, useState } from "react";
import TransportBourseFilter from "./TransportBourseFilter";
import TransportBourseList from "./TransportBourseList";

function TransportBourse({ setSubNavBarIsOpen, bourseTransports }) {
  const setGlobalRef = useZustandStore((state) => state.setGlobalRef);
  const [filterState, setFilterState] = useState([false, false, moment()._d]);
  const [searchFilter, setSearchFilter] = useState({
    carType: ""
  });
  const handleFilter = (index) => {
    setFilterState((prevStates) => {
      const newState = [...prevStates];
      if (index === 0 || index === 1) {
        newState[index] = !newState[index];
        if (newState[0] && newState[1]) {
          newState[index === 0 ? 1 : 0] = false;
        } else if (newState[1]) {
          newState[0] = false;
        } else if (newState[0]) {
          newState[1] = false;
        }
      }
      return newState;
    });
  };
  const handleDateFilter = (date) => {
    setFilterState((prevStates) => {
      const newState = [...prevStates];
      newState[2] = date;
      return newState;
    });
  };

  useEffect(() => {
    if (!filterState[0] && !filterState[1]) {
      setSearchFilter((prev) => ({ ...prev, carType: "" }));
    }
  }, [filterState]);
  return (
    <div onClick={(e) => setGlobalRef(e.target)}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100px important",
          marginBottom: "20px"
        }}
      >
        <p
          style={{
            fontSize: 20,
            fontWeight: 700,
            color: Palette.nightBlue,
            lineHeight: 1
          }}
        >
          Bourse de transports
        </p>

        <div className="cursor-pointer" onClick={setSubNavBarIsOpen}>
          <img
            width={20}
            height={20}
            src="icons/transport_tracking/close.svg"
            alt=""
          />
        </div>
      </div>
      <div className="my-3">
        <TransportBourseFilter
          handleFilter={handleFilter}
          handleDateFilter={handleDateFilter}
          setSearchFilter={setSearchFilter}
          searchFilter={searchFilter}
          filterState={filterState}
        />
      </div>
      <div
        className={"container flex"}
        style={{
          height: "374px",
          overflowY: "auto",
          scrollbarColor: "transparent transparent",
          scrollbarWidth: "none"
        }}
      >
        <TransportBourseList
          transports={bourseTransports.filter((item) => {
            return searchFilter.carType === ""
              ? moment(item.startDate).isSame(filterState[2], "day")
              : moment(item.startDate).isSame(filterState[2], "day") &&
                  item.carType === searchFilter.carType;
          })}
        />
      </div>
    </div>
  );
}

export default TransportBourse;
