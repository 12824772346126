import React, { useEffect, useRef } from "react";

const TexteScroller = ({ children, className, style }) => {
  const textRef = useRef(null); // Ref for the scrolling text
  const containerRef = useRef(null); // Ref for the container

  useEffect(() => {
    const textWidth = textRef.current.scrollWidth;
    const containerWidth = containerRef.current.offsetWidth;

    // Set initial overflow condition
    if (textWidth > containerWidth) {
      textRef.current.style.textOverflow = "ellipsis";
      textRef.current.style.overflow = "hidden";
      textRef.current.style.whiteSpace = "nowrap";
    }
  }, [children]);

  const handleMouseEnter = () => {
    const textWidth = textRef.current.scrollWidth;
    const containerWidth = containerRef.current.offsetWidth;
    const distance = textWidth - containerWidth;

    if (distance > 0) {
      textRef.current.style.transition = `transform ${distance / 100}s linear`;
      textRef.current.style.transform = `translateX(-${distance}px)`;
      textRef.current.style.overflow = "visible";
    }
  };

  const handleMouseLeave = () => {
    textRef.current.style.transition = "transform 0.5s ease-out";
    textRef.current.style.transform = "translateX(0)";
    textRef.current.style.overflow = "hidden";
  };

  return (
    <div
      ref={containerRef}
      style={{
        overflow: "hidden",
        whiteSpace: "nowrap"
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={textRef}
        style={{ transform: "translateX(0)", ...style }}
        className={className}
      >
        {children}
      </div>
    </div>
  );
};

export default TexteScroller;
