import { getEmployee } from "@actions/Employees";
import TransportCard from "@components/organisms/TransportCard";
import { Card } from "@components/shadcn/ui/card";
import { Separator } from "@components/shadcn/ui/separator";
import { useZustandStore } from "@helpers/store";
import { useClickOutside } from "@hooks/useClickOutside";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import NextTransportsCard from "./NextTransportsCard";
import PatientCard from "./PatientCard";
import TexteScroller from "./TexteScroller";
import TransportEnded from "./TransportEnded";
function VehiculeModal({
  isOpen,
  transport,
  handleClick,
  transportsEnded,
  nextDriverTransports,
  isAvailableDriver
}) {
  const [teamMate1, setTeamMate1] = useState(null);
  const [teamMate2, setTeamMate2] = useState(null);
  const { patient, driver } = transport ?? {};
  const { carType, name } = transport?.driver ?? "";
  const { carType: _carType, name: _name } = transportsEnded[0]?.driver ?? "";
  const ref = useRef(null);
  const globalRef = useZustandStore((state) => state.globalRef);
  const reverseGeoCodingAdress = useZustandStore(
    (state) => state.reverseGeoCodingAdress
  );
  useClickOutside(ref, handleClick, globalRef);
  transportsEnded.sort((a, b) => b.startDate.valueOf() - a.startDate.valueOf());
  useEffect(() => {
    driver?.teamMate1Id &&
      getEmployee(driver?.teamMate1Id).then((emp) => setTeamMate1(emp));
    driver?.teamMate2Id &&
      getEmployee(driver?.teamMate2Id).then((emp) => setTeamMate2(emp));
  }, [driver]);
  return (
    <div
      ref={ref}
      className="bg-background z-10  rounded-3xl border-solid border-4 border-borderV2 px-5 box-border relative custom-scrollbar"
      style={{
        display: `${isOpen ? "block" : "none"}`,
        width: "350px",
        height: "615px",
        top: 50
      }}
    >
      <div className="w-full pt-5 h-auto rounded-3xl flex flex-row  items-start mb-3">
        <div className="w-2/12 my-auto">
          {(carType === "VSL" || _carType === "VSL") && (
            <img width={35} src="/icons/veicule_info_modal/vslcar.svg" />
          )}
          {(carType === "AMBULANCE" || _carType === "AMBULANCE") && (
            <img width={35} src="/icons/veicule_info_modal/ambulance.svg" />
          )}
        </div>
        <div className="w-6/12 flex flex-col">
          <div style={{ lineHeight: 1 }} className="flex w-full justify-start">
            <p
              style={{
                marginRight: 4,
                fontSize: 13,
                color: "#FFB960",
                fontStyle: "italic",
                alignSelf: "flex-end"
              }}
            >
              {transport
                ? (carType === "AMBULANCE" && "AMB") ||
                  (carType === "VSL" && "VSL")
                : (_carType === "AMBULANCE" && "AMB") ||
                  (_carType === "VSL" && "VSL")}
            </p>
            <p
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: "#02196C"
              }}
            >
              {transport ? name : _name}
            </p>
          </div>
          <div
            className="flex justify-start w-full items-end"
            style={{ lineHeight: 1 }}
          >
            <p
              style={{
                fontSize: 12,
                color: "#02196C60",
                fontWeight: "bold",
                height: "fit-content"
              }}
            >
              <TexteScroller className="w-[262px] pr-1">
                {/* Dynamic rendering of teammates with span styling */}
                {driver?.teamMate1Id && (
                  <>
                    {teamMate1?.firstName} {teamMate1?.lastName}
                  </>
                )}
                {driver?.teamMate1Id && driver?.teamMate2Id && " - "}
                {driver?.teamMate2Id && (
                  <span style={{ color: "#DB2C73" }}>
                    {teamMate2?.firstName} {teamMate2?.lastName}
                  </span>
                )}
              </TexteScroller>
            </p>
          </div>
        </div>
        <div className="h-full">
          <div
            className="flex justify-center border w-24"
            style={{
              border: "solid 1px #DEE0F3",
              borderRadius: 10,
              backgroundColor: "#F6F7FF"
            }}
          >
            {transport?.driverStatus === "RETRIEVING_PATIENT" ||
            transport?.driverStatus === "DEPOSITING_PATIENT" ? (
              <>
                <img
                  width={10}
                  src="/icons/veicule_info_modal/dot_red.svg"
                  className="mr-1"
                />
                <p
                  style={{
                    color: "#FFBBC2",
                    marginLeft: 2,
                    fontSize: 12,
                    fontWeight: "bold"
                  }}
                >
                  En course
                </p>
              </>
            ) : (
              <>
                <img
                  width={10}
                  src="/icons/veicule_info_modal/dot_blue.svg"
                  className="mr-1"
                />
                <p
                  style={{
                    color: "#506BFF",
                    marginLeft: 2,
                    fontSize: 11,
                    fontWeight: "bold"
                  }}
                >
                  Disponible
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      {transportsEnded && !transport && (
        <div
          style={{ height: "650px" }}
          className={!transport && `custom-scrollbar`}
        >
          {transportsEnded &&
            !transport &&
            transportsEnded.map((item, i) => {
              const prevStartDate =
                i > 0 ? transportsEnded[i - 1].startDate : null;
              const isSameAsPrevDay =
                prevStartDate &&
                moment(prevStartDate).isSame(item.startDate, "d");
              return (
                <div style={{ width: "302px" }} key={item.id}>
                  {!isSameAsPrevDay && (
                    <VisibilitySensor offset={{ top: 300 }} partialVisibility>
                      <div
                        className="capitalize"
                        id={moment(item.startDate).format("YYYY-MM-DD")}
                        style={{
                          fontSize: 17,
                          fontWeight: "bold",
                          marginBottom: 10,
                          marginTop: i !== 0 ? 5 : 0,
                          color: "#02196C"
                        }}
                      >
                        {moment(item.startDate).format("dddd DD MMMM YYYY")}
                      </div>
                    </VisibilitySensor>
                  )}
                  <TransportEnded key={item.id} transportEnded={item} />
                </div>
              );
            })}
        </div>
      )}
      {transport && (
        <>
          <div style={{ width: "302px" }} className="rounded-3xl">
            <PatientCard patient={patient} />
          </div>
          <div className="rounded-3xl m-auto">
            {!isAvailableDriver && <TransportCard transport={transport} />}
          </div>
          <Separator className="mx-auto w-5/6 h-0.5 bg-borderV2" />
          <Card className="relative  border-none">
            <p className="mx-3 pt-1 text-nightBlue font-bold text-lg text-center">
              Prochains transports
            </p>
            <div
              style={{ height: "130px", scrollbarWidth: "none" }}
              className="m-auto overflow-y-scroll"
            >
              {nextDriverTransports &&
                nextDriverTransports.map((item, i) => {
                  const prevStartDate =
                    i > 0 ? nextDriverTransports[i - 1].startDate : null;
                  const isSameAsPrevDay =
                    prevStartDate &&
                    moment(prevStartDate).isSame(item.startDate, "d");
                  return (
                    <div key={item.id}>
                      {!isSameAsPrevDay && (
                        <VisibilitySensor
                          offset={{ top: 300 }}
                          partialVisibility
                        >
                          <div
                            className="capitalize"
                            id={moment(item.startDate).format("YYYY-MM-DD")}
                            style={{
                              fontSize: 17,
                              fontWeight: "bold",
                              marginBottom: 10,
                              marginTop: i !== 0 ? 5 : 0,
                              color: "#02196C"
                            }}
                          >
                            {moment(item.startDate).format("dddd DD MMMM YYYY")}
                          </div>
                        </VisibilitySensor>
                      )}
                      <NextTransportsCard driverTransport={item} />
                    </div>
                  );
                })}
            </div>
          </Card>
        </>
      )}
    </div>
  );
}

export default VehiculeModal;
