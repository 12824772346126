import { Card, CardHeader } from "@components/shadcn/ui/card";
import { REASONS } from "@data/transports";
import { capitalize } from "lodash-es";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import BadgeRegulationModal from "./BadgeRegulationModal";
import TexteScroller from "./TexteScroller";
function RegulationModalTransportCard({ transport }) {
  const {
    returnTransportId,
    startDate,
    endDate,
    reason,
    status,
    isAssign,
    driverStatus,
    regulatedBy
  } = transport;
  const {
    postalCode: postalCodeFrom = "",
    city: cityFrom = "",
    name: nameFrom = "",
    address: addressFrom = "",
    phoneNumber: phoneNumberFrom = "",
    complement: complementFrom = "",
    moreNotes: moreNotesFrom = ""
  } = transport.from;

  const {
    postalCode: postalCodeTo = "",
    city: cityTo = "",
    name: nameTo = "",
    address: addressTo = "",
    phoneNumber: phoneNumberTo = "",
    moreNotes: moreNotesTo = "",
    service = "",
    establishment = "",
    chamber = ""
  } = transport.to;

  const { text: distance } = transport.tripDistanceAndDuration.distance ?? {};
  const { text: duration } = transport.tripDistanceAndDuration.duration ?? {};
  const { value: durationInSencode } =
    transport.tripDistanceAndDuration.duration ?? {};
  // Calculate expected arrival time
  const expectedArrivalTime = moment(startDate)
    .add({ seconds: durationInSencode })
    .format("LT");

  const reasonFr = () => {
    return REASONS.filter((item) => item.value === reason)[0]?.name;
  };
  //Show transport details
  const history = useHistory();
  const handleTransportDetails = () => {
    history.push({
      search: `?transport=${transport.id}`
    });
  };
  const regulationCardstyle = [
    {
      containerClassName:
        "flex flex-row item-center px-2 justify-center w-full border-borderV2 rounded-[10px] border-2 bg-[#F6F7FF]",
      imgSrc: "/icons/veicule_info_modal/dot_blue.svg",
      text: "A réguler",
      textClassName: "whitespace-nowrap text-skyBlue2 pl-[5px] !text-[11px]"
    },
    {
      containerClassName:
        "flex flex-row item-center px-2 justify-center w-full border-borderV2 rounded-[10px] border-2 bg-[#F6F7FF]",
      imgSrc: "/icons/transport_regulation/check.svg",
      text: "Terminé",
      textClassName: "whitespace-nowrap text-chateauGreen pl-[5px] !text-[11px]"
    },
    {
      containerClassName:
        "flex flex-row item-center px-2 justify-center w-full border-borderV2 rounded-[10px] border-2 bg-[#F6F7FF]",
      imgSrc: "/icons/transport_regulation/x.svg",
      text: "Annulé",
      textClassName: "whitespace-nowrap text-pink pl-[5px] !text-[11px]"
    },
    {
      containerClassName:
        "flex flex-row item-center px-2 justify-center w-full border-borderV2 rounded-[10px] border-2 bg-[#F6F7FF]",
      imgSrc: "/icons/transport_regulation/target.svg",
      text: "Bourse",
      textClassName:
        "whitespace-nowrap text-selectiveYellow pl-[5px] !text-[11px]"
    },
    {
      containerClassName:
        "flex flex-row item-center px-2 justify-center w-full border-borderV2 rounded-[10px] border-2 bg-[#F6F7FF]",
      imgSrc: "/icons/veicule_info_modal/dot_red.svg",
      text: "En cours",
      textClassName:
        "whitespace-nowrap text-festiveFennec pl-[5px]  !text-[11px]"
    },
    {
      containerClassName:
        "flex flex-row item-center px-2 justify-center w-full border-borderV2 rounded-[10px] border-2 bg-[#F6F7FF]",
      imgSrc: "/icons/veicule_info_modal/dot_blue.svg",
      text: "Nouveau",
      textClassName: "whitespace-nowrap text-neonBlue pl-[5px] !text-[11px]"
    },
    {
      containerClassName:
        "flex flex-row item-center px-2 justify-center w-full border-borderV2 rounded-[10px] border-2 bg-[#F6F7FF]",
      imgSrc: "",
      text: "Attribué",
      textClassName: "whitespace-nowrap text-neonBlue !text-[11px]"
    },
    {
      containerClassName:
        "flex flex-row item-center px-2 justify-center w-full border-borderV2 rounded-[10px] border-2 bg-[#F6F7FF]",
      imgSrc: "",
      text: "Attribué",
      textClassName: "whitespace-nowrap text-neonBlue !text-[11px]"
    }
  ];
  const regulationCardstyleBadge = (status) => {
    if (status === "ACCEPTED" && !isAssign) {
      return regulationCardstyle[0];
    }
    if (status === "CANCELED") {
      return regulationCardstyle[2];
    }
    if (status === "ACCEPTED" && driverStatus === "PENDING") {
      return regulationCardstyle[6];
    }
    if (status === "ACCEPTED" && driverStatus === "ACCEPTED") {
      return regulationCardstyle[7];
    }
    if (status === "ENDED") {
      return regulationCardstyle[1];
    }
    if (status === "IN_PROGRESS") {
      return regulationCardstyle[4];
    }
    if (
      status === "PENDING" &&
      regulatedBy === "BOURSE" &&
      isAssign === false
    ) {
      return regulationCardstyle[3];
    }
    if (status === "PENDING" && regulatedBy === "BOURSE" && isAssign === null) {
      return regulationCardstyle[5];
    }
  };
  const { containerClassName, imgSrc, imgClassName, text, textClassName } =
    regulationCardstyleBadge(status);
  return (
    <Card className="relative border-none">
      <CardHeader className="px-0 pb-2">
        <div className="flex flex-row justify-between  text-lightBlue text-sm font-bold italic">
          <div className="flex flex-row items-end">
            Transport
            <span className="!text-pink">
              {returnTransportId ? " Aller - Retour" : " Aller"}
            </span>
          </div>
          <div style={{ fontStyle: "normal" }} className="h-full">
            <BadgeRegulationModal
              containerClassName={containerClassName}
              imgWidth="7px"
              imgSrc={imgSrc}
              imgClassName={imgClassName}
              text={text}
              textClassName={textClassName}
            />
          </div>
        </div>
        <div className="!mt-0">
          <p
            style={{ fontSize: "18px", lineHeight: "1.2rem" }}
            className="text-nightBlue font-bold"
          >
            {capitalize(moment(startDate).format("dddd DD MMMM"))}
          </p>
          <div className="flex flex-row justify-between">
            <p
              style={{ lineHeight: "1.2rem" }}
              className="text-lightBlue text-sm font-bold italic"
            >
              Pris en charge pour
              <span className="!text-pink"> {capitalize(reasonFr())}</span>
            </p>
            <div onClick={handleTransportDetails} className="cursor-pointer">
              <img
                width={17}
                src="/icons/veicule_info_modal/transport_info.svg"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row w-full">
          <div>
            <p style={{ fontSize: 14 }} className="!text-pink font-bold">
              {moment(startDate).format("LT")}
            </p>
            {returnTransportId ? (
              <img
                className="m-auto"
                height={20}
                src="icons/veicule_info_modal/ar_icon.svg"
              />
            ) : (
              <img
                className="m-auto"
                height={20}
                src="icons/veicule_info_modal/a_icon.svg"
              />
            )}
            <p style={{ fontSize: 14 }} className="!text-pink font-bold">
              {moment(endDate).format("LT")}
            </p>
          </div>
          <div className="flex flex-col pl-2">
            <div className="h-1/2">
              <TexteScroller className="w-[262px] text-[15px] text-[#02196c]">
                {nameFrom}
              </TexteScroller>
              <TexteScroller className="w-[262px] text-[12px] text-[#02196c60] leading-[1]">
                {addressFrom}
              </TexteScroller>
              <TexteScroller className="w-[262px] text-[12px] text-[#02196c60] leading-[1]">
                {`${postalCodeFrom} ${cityFrom}`}
              </TexteScroller>
              <TexteScroller className="w-[262px] text-[12px] text-pink leading-[1] pl-2  mt-[5px]">
                {`${phoneNumberFrom ?? ""}`}
              </TexteScroller>
              <TexteScroller className="w-[262px] text-[12px] text-pink leading-[1] pl-2">
                {`${complementFrom || ""}${
                  complementFrom && moreNotesFrom ? " / " : ""
                }${
                  moreNotesFrom && moreNotesFrom !== "null" ? moreNotesFrom : ""
                }`}
              </TexteScroller>
            </div>
            <div className="flex flex-col h-1/2 justify-end mt-[42px]">
              <TexteScroller className="w-[262px] text-[15px] text-[#02196c]">
                {nameTo}
              </TexteScroller>
              <TexteScroller className="w-[262px] text-[12px] text-[#02196c60] leading-[1]">
                {addressTo}
              </TexteScroller>
              <TexteScroller className="w-[262px] text-[12px] text-[#02196c60] leading-[1]">
                {`${postalCodeTo} ${cityTo}`}
              </TexteScroller>
              <TexteScroller className="w-[262px] text-[12px] text-pink leading-[1] pl-2  mt-[5px]">
                {`${phoneNumberTo}`}
              </TexteScroller>
              <TexteScroller className="w-[262px] text-[12px] text-pink leading-[1] pl-2">
                {`${service ? `Service ${service} / ` : ""}${
                  establishment
                    ? `Bâtiment ${establishment}${chamber ? " / " : ""}`
                    : ""
                }${chamber ? `Chambre ${chamber}` : ""}`.trim()}
              </TexteScroller>
              <TexteScroller className="w-[262px] text-[12px] text-pink leading-[1] pl-2">
                {`${moreNotesTo ?? ""}`}
              </TexteScroller>
            </div>
          </div>
        </div>
        <div>
          <p className="my-4 text-lightBlue italic font-semibold text-center whitespace-pre text-xs">{`Estimation : ~ ${duration} & ${distance} de route\n Arrivée prévu à ${expectedArrivalTime}`}</p>
        </div>
      </CardHeader>
    </Card>
  );
}

export default RegulationModalTransportCard;
