import { useZustandStore } from "@helpers/store";
import moment from "moment";
import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import TransportRegulationCard from "./TransportRegulationCard";

function TransportRegulationList({ transports }) {
  const [selectedTransport, setSelectedTransport] = useState({
    id: null,
    isSelected: false
  });
  const setRegulatedTransport = useZustandStore(
    (state) => state.setRegulatedTransport
  );
  const setIsBourseModalOpen = useZustandStore(
    (state) => state.setIsBourseModalOpen
  );
  const setIsDriverModalOpen = useZustandStore(
    (state) => state.setIsDriverModalOpen
  );
  const setIsOpen = useZustandStore((state) => state.setIsOpen);
  const setGlobalRef = useZustandStore((state) => state.setGlobalRef);
  const setIsRegulationModalOpen = useZustandStore(
    (state) => state.setIsRegulationModalOpen
  );
  const setTransportId = useZustandStore((state) => state.setTransportId);
  const filteredTransports = transports.map((transport) => {
    if (transport.returnTransportId) {
      return { ...transport, hidden: false }; 
    }
    if (transport.goToTransportId) {
      const correspondingAllerTransport = transports.find(
        (t) => t.id === transport.goToTransportId
      );
      if (
        correspondingAllerTransport &&
        correspondingAllerTransport.status === "ENDED"
      ) {
        return { ...transport, hidden: false };
      }
      return { ...transport, hidden: true };
    }
    return { ...transport, hidden: false };
  });

  return (
    <div style={{ height: "410px" }} className={`custom-scrollbar`}>
      {filteredTransports.length > 0 &&
        filteredTransports.map((item, i) => {
          const prevStartDate = i > 0 ? transports[i - 1].startDate : null;
          const isSameAsPrevDay =
            prevStartDate && moment(prevStartDate).isSame(item.startDate, "d");
          return (
            <div
              onClick={(e) => {
                setRegulatedTransport(item);
                setGlobalRef(e.target);
                setIsRegulationModalOpen(true);
                setIsBourseModalOpen(false);
                setIsOpen(false);
                setIsDriverModalOpen([false, false]);
                setTransportId(item.id);
                setSelectedTransport({ id: item.id, isSelected: true });
              }}
              style={{ width: "320px" }}
              key={item.id}
            >
              {!isSameAsPrevDay && (
                <VisibilitySensor offset={{ top: 300 }} partialVisibility>
                  <div
                    className="capitalize"
                    id={moment(item.startDate).format("YYYY-MM-DD")}
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      marginBottom: 5,
                      marginTop: i !== 0 ? 5 : 0,
                      color: "#02196C"
                    }}
                  >
                    {moment(item.startDate).format("dddd DD MMMM YYYY")}
                  </div>
                </VisibilitySensor>
              )}
              {!item.hidden && (
                <TransportRegulationCard
                  key={item.id}
                  transport={item}
                  selectedTransport={selectedTransport}
                />
              )}
            </div>
          );
        })}
    </div>
  );
}

export default TransportRegulationList;
