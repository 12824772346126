import { Card } from "@components/shadcn/ui/card";
import moment from "moment";
import React from "react";
import TexteScroller from "./TexteScroller";
function TransportBourseCard({ bourseTransport, isSelected }) {
  const { startDate, endDate, carType } = bourseTransport;
  const { postalCode: postalCodeFrom, city: cityFrom } = bourseTransport.from;
  const { postalCode: postalCodeTo, city: cityTo } = bourseTransport.to;
  
  return (
    <Card
      className={`bg-lightGray2 w-full ${
        isSelected ? "border-lavenderBlue" : "border-blueWaiting"
      }  border-2 relative mb-3 p-3 cursor-pointer hover:border-lavenderBlue`}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "end",
            justifyContent: "center",
            width: "40%",
            whiteSpace: "nowrap"
          }}
        >
          <div className="text-skyBlue2 font-bold text-[18px] leading-3">
            {moment(startDate).format("LT")}
          </div>
          <TexteScroller className="text-skyBlue2 font-bold text-[12px] leading-3 py-1 mt-[3px]">
            {cityFrom}
          </TexteScroller>

          <div className="text-wewak font-bold text-[13px] leading-3">
            {postalCodeFrom}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "space-between",
            alignItems: "center",
            width: "23%"
          }}
        >
          <div>
            {carType === "VSL" && (
              <img width={25} src="/icons/veicule_info_modal/vslcar.svg" />
            )}
            {carType === "AMBULANCE" && (
              <img width={25} src="/icons/veicule_info_modal/ambulance.svg" />
            )}
          </div>
          <div>{<img width={45} src="/icons/transport_bourse/trip.svg" />}</div>
          <div className="text-neonBlue text-[12px] italic leading-3">
            {bourseTransport?.returnTransportId ? (
              <img width={18} src="/icons/transport_bourse/repeat.svg" />
            ) : (
              <img
                width={18}
                src="/icons/transport_bourse/arrow-right-circle.svg"
              />
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "start",
            justifyContent: "space-center",
            width: "40%",
            whiteSpace: "nowrap"
          }}
        >
          <div className="text-skyBlue2 font-bold text-[18px] leading-3">
            {moment(endDate).format("LT")}
          </div>
          <TexteScroller className="text-skyBlue2 font-bold text-[12px] leading-3 py-1 mt-[3px]">
            {cityTo}
          </TexteScroller>
          <div className="text-wewak font-bold text-[13px] leading-3">
            {postalCodeTo}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default TransportBourseCard;
